import React, { Fragment, useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationContext } from "../../../context/LocationContext";

const UserBox = ({ showDropdowns }) => {
  const { account, accountSubscriptionLevel } = useContext(AccountContext); 
//console.log("showDropdowns",showDropdowns);
  return (
    <Fragment>
      <div className="header-btn-lg pe-0" style={{ paddingLeft: '5px' }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            {!showDropdowns ? (
              <>
                <div className="widget-content-left ms-3 header-user-info">
                  <div className="widget-heading" style={{textAlign: 'left'}}>{account?.fname} {account?.lname}</div>
                  <div className="widget-subheading" style={{textAlign: 'left', width: '183px', overflow:'hidden'}}>{account?.company_name}</div>
                </div>
              </>
            ) : (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="Dark" className="p-0 d-flex align-items-center" style={{width: '200px'}}>
                  <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} style={{fontSize: '1rem'}} />
                  <div className="widget-content-left ms-3 header-user-info">
                    <div className="widget-heading" style={{textAlign: 'left'}}>{account?.fname} {account?.lname}</div>
                    <div className="widget-subheading" style={{textAlign: 'left', width: '183px', overflow:'hidden'}}>{account?.company_name}</div>
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{width: '15rem'}}>
                    <PerfectScrollbar>
                      <Nav vertical>
                        <NavItem>
                          <NavLink href="/account">
                            My Account
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="/account/new_password">
                            Reset Password
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="/account/locations">
                            Locations
                          </NavLink>
                        </NavItem>
                        {(accountSubscriptionLevel >= 2) && (
                          <>
                          <NavItem>
                            <NavLink href="/account/connect">
                              Integrations
                            </NavLink>
                          </NavItem>
                          </>
                        )}
                        <NavItem>
                          <NavLink href="/account/support">
                            Support
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="/account/logout">
                            Log Out
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </PerfectScrollbar>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
