import React, { createContext, useState, useEffect } from 'react';
import supabase from '../lib/supabase';

const AccountContext = createContext();
const UpdateAccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);
  //const [paddleSubscriptions, setPaddleSubscriptions] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [accountSubscriptionLevel, setAccountSubscriptionLevel] = useState(1);
  const [hasSession, setHasSession] = useState(null);

  // Fetch the user session and account info from Supabase
  const fetchUser = async () => {
    //console.log("in fetchUser");
  
    try {
      //console.log("Before getSession")
      const { data, error } = await supabase.auth.getSession();
      //console.log("After getSession")
  
      if (error) {
        console.error("Error fetching session:", error);
        return;
      }
  
      const session = data?.session;
      //console.log("session", session);
  
      if (!session) {
        //console.log("!session");
        setUser(null);
        setAccount(null);
        setHasSession(false);
        return;
      }
  
      const user = session.user;
      user.access_token = session.access_token;
      setUser(user);
      setHasSession(true);
  
      const { data: userData, error: userError } = await supabase
        .from("userinfo")
        .select(
          "*, oauth_credentials(integration_name, merchant_id, access_token_encrypted, last_sync), paddle_subscriptions(paddle_subscription_id, paddle_customer_id, status, next_bill_date, paddle_subscription_items(paddle_product_id, quantity))"
        )
        .eq("user_id", user.id)
        .single();
      //console.log(userData);

      if (userError) {
        console.error("Error fetching user info:", userError);
        return;
      }

      const accountData = {
        ...userData,
        square_connected: userData?.oauth_credentials?.some(
          (row) => row.integration_name === "square" && row.merchant_id != null
        ),
        has_synced: userData?.oauth_credentials?.some(
          (row) => row.integration_name === "square" && row.last_sync != null
        ),
        is_pos_connected: userData?.oauth_credentials?.some(
          (row) => row.integration_name === "square" && row.merchant_id != null
        ),
        google_connected: userData?.oauth_credentials?.some(
          (row) => row.integration_name === "google" && row.access_token_encrypted != null
        ),
        is_syncing: false,
      };

      let paddle_customer_id = accountData?.paddle_subscriptions?.[0]?.paddle_customer_id;
      //console.log("Paddle Customer ID From DB Table:",paddle_customer_id,accountData?.paddle_customers, accountData.user_id);
      
      /*
      const paddle_subscriptions = accountData?.paddle_subscriptions
        ?.filter(item => item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'past_due')
        ?.reduce((acc, item) => {
          //console.log("item",item);
          const { paddle_subscription_id, status, next_bill_date, paddle_subscription_items } = item;
          //console.log("paddle_subscription_items",paddle_subscription_items);
          const { paddle_product_id, quantity } = paddle_subscription_items?.[0];
          //if (!paddle_customer_id) paddle_customer_id = v_paddle_customer_id;

          // Find existing product group
          const existing = acc.find(sub => sub.paddle_product_id === paddle_product_id);
          if (existing) {
            existing.quantity += quantity || 0;
            if (new Date(next_bill_date) < existing.next_bill_date) { //We're looking for the soonest next bill date
              existing.next_bill_date = new Date(next_bill_date);
            }
            if (!existing?.paddle_customer_id && paddle_customer_id) {
              existing.paddle_customer_id = paddle_customer_id;
            }
          } else {
            acc.push({
              paddle_product_id,
              paddle_subscription_id,
              paddle_customer_id,
              status,
              next_bill_date: new Date(next_bill_date),
              quantity: quantity || 0,
            });
          }

          return acc;
      }, []);
      //console.log("paddle_subscriptions",paddle_subscriptions)

      //console.log("Paddle Customer ID after subscriptions:",paddle_customer_id);

      //console.log("paddle_subscriptions in AccountContext:", paddle_subscriptions, accountData?.paddle_subscriptions);

      paddle_subscriptions.hasPastDue = paddle_subscriptions?.some(item => item.status.toLowerCase() === 'past_due');

      setPaddleSubscriptions(paddle_subscriptions);
      */
      setAccount(accountData);

      if (paddle_customer_id) {
        window.profitwell('start', { 'user_id': paddle_customer_id });
      }

      const initialSubscriptions = {
        Pro: { total: 0, items: [] },
        KDS: { total: 0, items: [] },
      };
      
      const subscriptions = accountData?.paddle_subscriptions
      ?.filter(sub => {
        const status = sub.status?.toLowerCase();
        return status === 'active' || status === 'past_due';
      })
      ?.reduce((acc, sub) => {
        const {
          paddle_subscription_id,
          status,
          next_bill_date,
          paddle_subscription_items,
          paddle_customer_id
        } = sub;

        if (Array.isArray(paddle_subscription_items)) {
          paddle_subscription_items.forEach(item => {
            const { paddle_product_id, quantity } = item;
            const qty = quantity || 0;
          
            if (paddle_product_id === process.env.REACT_APP_PADDLE_PRO_PRODUCT_ID) {
              // Update running total for Pro
              acc.Pro.total += qty;
          
              // Check if we already have this subscription in our Pro items
              const existing = acc.Pro.items.find(
                (s) => s.paddle_subscription_id === paddle_subscription_id
              );
              if (existing) {
                existing.quantity += qty;
                if (new Date(next_bill_date) < existing.next_bill_date) {
                  existing.next_bill_date = new Date(next_bill_date);
                }
                if (!existing.paddle_customer_id && paddle_customer_id) {
                  existing.paddle_customer_id = paddle_customer_id;
                }
              } else {
                acc.Pro.items.push({
                  paddle_product_id,
                  paddle_subscription_id,
                  paddle_customer_id,
                  status,
                  next_bill_date: new Date(next_bill_date),
                  quantity: qty,
                });
              }
            } else if (paddle_product_id === process.env.REACT_APP_PADDLE_KDS_PRODUCT_ID) {
              // Update running total for KDS
              acc.KDS.total += qty;
          
              // Check if we already have this subscription in our KDS items
              const existing = acc.KDS.items.find(
                (s) => s.paddle_subscription_id === paddle_subscription_id
              );
              if (existing) {
                existing.quantity += qty;
                if (new Date(next_bill_date) < existing.next_bill_date) {
                  existing.next_bill_date = new Date(next_bill_date);
                }
                if (!existing.paddle_customer_id && paddle_customer_id) {
                  existing.paddle_customer_id = paddle_customer_id;
                }
              } else {
                acc.KDS.items.push({
                  paddle_product_id,
                  paddle_subscription_id,
                  paddle_customer_id,
                  status,
                  next_bill_date: new Date(next_bill_date),
                  quantity: qty,
                });
              }
            }
          });
        }
        
        return acc;
      }, initialSubscriptions);

      
      subscriptions.total = subscriptions.Pro.total + subscriptions.KDS.total;
      //console.log("Subscriptions",subscriptions);
      subscriptions.hasPastDue = subscriptions.Pro.items.some(item => item.status.toLowerCase() === 'past_due') || subscriptions.KDS.items.some(item => item.status.toLowerCase() === 'past_due');
      const allItems = [...subscriptions.Pro.items, ...subscriptions.KDS.items];
      subscriptions.next_bill_date = allItems.reduce((earliest, item) => {
        // If earliest is null, set it to the current item's next_bill_date,
        // otherwise compare and keep the earlier date.
        return !earliest || item.next_bill_date < earliest ? item.next_bill_date : earliest;
      }, null);

      setSubscriptions(subscriptions);
    
    } catch (err) {
      console.error("Unexpected error in fetchUser:", err);
    }
  };

  useEffect(() => {
    if (subscriptions?.Pro?.total > 0) {
      setAccountSubscriptionLevel(3);
    } else if (subscriptions?.KDS?.total > 0) {
      setAccountSubscriptionLevel(2);
    }
  },[subscriptions])

/*
  useEffect(() => {
    console.log("in useEffect() []")
    // Listen for the visibilityState to update session when visible
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        console.log("Visibility changed = visible");
        fetchUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial fetch
    console.log("calling fetchUser")
    fetchUser();

    // Cleanup function
    return () => {
      //console.log("Cleaning up...");
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  */

  useEffect(() => {
    fetchUser();
  },[]);
  
  const login = async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  
      if (!error) {
        fetchUser(); // Call fetchUser if no error
      }
  
      return { data, error }; // Return data and error directly
    } catch (err) {
      console.error('Unexpected error during login:', err);
      throw err; // Rethrow the error to be handled by the caller
    }
  };
  
  const logoutEverywhere = async () => {
    console.log("Logging out everywhere!");
    const { error } = await supabase.auth.signOut({ scope:'global' });
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      setUser(null);
      setHasSession(false);
    }
  };

  const logout = async () => {
    console.log("Logging out (just here)!");
    
    const { error } = await supabase.auth.signOut({ scope:'local' });
    
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      setUser(null);
      setHasSession(false);
    }
  };  

  const menuSync = async () => {
    console.log("MenuSync");
    if (!user.access_token) {
      console.error("Error: No user");
      return false;
    }
    if (account?.square_connected) {
      try {
        console.log("Syncing Menu");
        setAccount((prevAccount) => ({
          ...prevAccount,
          is_syncing: true,
        }));
        const response = await fetch('https://yzxiqswbpvwvdzgwktpt.supabase.co/functions/v1/square-sync', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          }
        });
        if (!response.ok) {
          console.error(response.error);
          return false;
        }
        setAccount((prevAccount) => ({
          ...prevAccount,
          has_synced: true,
        }));
        return true;
      } catch (ex) {
        console.error("Error in order refresh from api:",ex);
      } finally {
        setAccount((prevAccount) => ({
          ...prevAccount,
          is_syncing: false,
        }));
      }
    }
    return false;
  };

  //Removed from the provider value below: paddleSubscriptions, 
  return (
    <AccountContext.Provider value={{ account, user, subscriptions, accountSubscriptionLevel, hasSession, setAccount, fetchUser, login, logout, logoutEverywhere, menuSync }}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContext, UpdateAccountContext, AccountProvider };
